import React from "react";
import theme from "theme";
import { Theme, Link, Image, Span, U, Text, Section, Strong, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Sacred Chile Co. - Preserving the Ancient Heritage of New Mexican chile
			</title>
			<meta name={"description"} content={"Preserving the Ancient Heritage of New Mexican chile"} />
			<meta property={"og:title"} content={"Sacred Chile Co. - Preserving the Ancient Heritage of New Mexican chile"} />
			<meta property={"og:description"} content={"Preserving the Ancient Heritage of New Mexican chile"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Background-Source-File.png?v=2024-02-16T05:52:13.016Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Icon-Source-File.jpg?v=2024-02-16T18:30:16.457Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Icon-Source-File.jpg?v=2024-02-16T18:30:16.457Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Icon-Source-File.jpg?v=2024-02-16T18:30:16.457Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Icon-Source-File.jpg?v=2024-02-16T18:30:16.457Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Icon-Source-File.jpg?v=2024-02-16T18:30:16.457Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Icon-Source-File.jpg?v=2024-02-16T18:30:16.457Z"} />
			<meta name={"msapplication-TileColor"} content={"#04080C"} />
		</Helmet>
		<Section
			padding="50px 0 100px 0"
			quarkly-title="HeroBlock"
			font="normal 700 72px/1.1 &quot;Inter&quot;, sans-serif"
			color="#231f20"
			background="#231f20"
		>
			<Image
				width="100%"
				src="https://uploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Background-Source-File.png?v=2024-02-16T05:52:13.016Z"
				align-self="auto"
				order="0"
				display="block"
				flex="0 1 auto"
				object-fit="cover"
				height="800px"
				margin="0px 0px 80px 0px"
				color="#d8633a"
				overflow-x="visible"
				sm-height="max-content"
				srcSet="https://smartuploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Background-Source-File.png?v=2024-02-16T05%3A52%3A13.016Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Background-Source-File.png?v=2024-02-16T05%3A52%3A13.016Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Background-Source-File.png?v=2024-02-16T05%3A52%3A13.016Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Background-Source-File.png?v=2024-02-16T05%3A52%3A13.016Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Background-Source-File.png?v=2024-02-16T05%3A52%3A13.016Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Background-Source-File.png?v=2024-02-16T05%3A52%3A13.016Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65ced69d009abe001f03809a/images/SACRED-CHILE-CO-Orange-Background-Source-File.png?v=2024-02-16T05%3A52%3A13.016Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Text
				sm-margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0"
				color="--lightD1"
				md-font="normal 700 52px/1.1 &quot;Inter&quot;, sans-serif"
				sm-font="normal 700 32px/1.3 --fontFamily-googleBitter"
				sm-text-align="center"
				font="normal 700 72px/1.1 --fontFamily-googleBitter"
				md-text-align="center"
			>
				Hi! I'm New Mexican chile{" "}
				<Span font-weight="normal">
					(Red & Green)
				</Span>
				. I'm tired of being a premium product while being treated as if I was a common spice.{" "}
				<U>
					This ends now
				</U>
				. I am considered sacred.
			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="74px 0 74px 0"
			quarkly-title="Footer"
			color="#231f20"
			background="#231f20"
		>
			<Override slot="SectionContent" md-align-items="center" flex-direction="row" md-flex-direction="column" />
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				md-justify-content="center"
				md-align-items="center"
				md-padding="16px 16px 16px 16px"
				md-width="100%"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				display="flex"
			>
				<Text margin="0px 0px 0px 0px" font="normal 400 18px/1.5 --fontFamily-googleBitter" color="--light" sm-text-align="center">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Sacred Chile Co
					</Strong>
					<br />
					Grown, Processed & Packaged in New Mexico
				</Text>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});